
/* sub-visual */
.sub-visual {
    position:relative;
    display: flex;
    align-items: center;
    height: 200px;
    padding-top: var(--header-body-height);
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: -1;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.sub-tab{
    ::v-deep{
        .container{
            max-width: var(--container);
            padding: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 300px;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 540px;
    }
}
@media (min-width: 1200px) {
}
